import { Usuario } from '@/models'

export function criarUsuario(): Usuario {
	return {
		id: '',
		email: null,
		nome: '',
		login: '',
		cpf: null,
		ativo: true,
		perfil: {
			id: '',
			nome: '',
			permissoes: [],
			gruposEconomicos: [],
			percentualMaximoDesconto: 0,
			tempoParaLogoff: '',
			percentualMaximoDescontoPorItem: 0,
			tempoLimiteDeInatividade: '',
			telaInicial: '',
		},
		tags: [],
		lojas: [],
		avatar: '',
		senha: '',
		pdvs: [],
		telefones: [],
		vendedor: false,
		criaFaq: false,
		gruposEconomicos: [],
		autoLoginAoAbrirCaixa: false,
		codigoExterno: undefined,
	}
}
